.what-you-get {

	&-box {
		padding: 40px 20px;
		position: relative;

		@include media-breakpoint-up(md) {
			padding: 60px 50px;
		}

		&:after {
			background: radial-gradient(50% 50% at 50% 50%, #CF18FF 0%, #8D2BDB 100%);
			border-radius: 20px;
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;

			@include media-breakpoint-up(md) {
				border-radius: 0 40px 40px 0;
				left: 50%;
				margin-left: calc(-50vw - 1px);
			}
		}
	}

	&-slider-wrapper {
		margin-left: -20px;
		margin-right: -20px;
		overflow: hidden;
		padding-top: rem(40px);
		position: relative;

		@include media-breakpoint-up(md) {
			margin-left: -50vw;
			margin-right: 0;
			padding-bottom: 0;
		}

		@include media-breakpoint-up(lg) {
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	&-slider {
		margin-left: -10px;
		margin-right: -10px;

		img,
		video {
			aspect-ratio: 9 / 16;
			height: auto;
			object-fit: cover;
			width: 100%;
		}
	}

	.swiper {
		margin-right: 0;

		&-slide {
			flex: 0 0 192px;
			max-width: 192px;
			padding-left: 10px;
			padding-right: 10px;
			width: 192px;

			@include media-breakpoint-up(md) {
				flex-basis: 384px;
				max-width: 384px;
				width: 384px;
			}
		}

		&-buttons {
			gap: 20px;
			padding-right: 10px;
			position: relative;

			@include media-breakpoint-up(lg) {
				padding-top: 60px;
			}
		}

		&-button {
			display: flex;
			left: 0;
			margin: 0;
			position: relative;
			right: 0;
			top: 0;

			&:after {
				display: none;
			}

			&-prev {
				transform: rotate(90deg);
			}

			&-next {
				transform: rotate(-90deg);
			}

			&-disabled {
				cursor: not-allowed;
				opacity: .25;

				&:hover,
				.btn-check:checked + &,
				&.active,
				&.show,
				&:first-child:active,
				:not(.btn-check) + &:active {
					background-color: $electric-violet;

					svg {
						stroke: $white;
					}
				}
			}
		}
	}

	&-video-wrapper {
		border-radius: 14px;
		cursor: pointer;
		overflow: hidden;
		position: relative;

		.btn {
			left: 50%;
			margin: rem(-15px 0 0 -15px);
			opacity: 1;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;
			z-index: 2;

			@include media-breakpoint-up(md) {
				margin: rem(-26px 0 0 -26px);
			}

			svg {
				fill: $white;
				stroke: none;
				transition: fill 200ms ease-in-out;

				&:last-child {
					display: none;
				}
			}

			&:hover,
			.btn-check:checked + &,
			&.active,
			&.show,
			&:first-child:active,
			:not(.btn-check) + &:active {

				svg {
					fill: $electric-violet;
					stroke: none;
				}
			}

			+ span {
				background-color: $black;
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				transition: opacity 200ms ease-in-out;
				z-index: 1;
			}
		}

		.is-playing + .btn {
			opacity: 0;
		}

		.mousemove + .btn {
			opacity: 1;
		}

		.is-playing.mousemove + .btn + span {
			opacity: .2;
		}

		.is-playing + .btn svg {

			&:first-child {
				display: none;
			}

			&:last-child {
				display: block;
			}
		}
	}

	&-text {

		@include media-breakpoint-up(lg) {
			flex: 0 0 rem(400px);
			margin-left: 95px;
			max-width: 100%;
			width: rem(400px);
		}

		.editor {

			h2,
			.h2 {
				font-size: rem(36px);
				line-height: rem(40px);
				margin-bottom: rem(30px);
			}

			p,
			.p {
				font-size: rem(18px);
				line-height: rem(25px);
			}
		}

		.actions {
			padding-top: rem(40px);
		}

		&-icon {
			background-color: $catskill-white;
			border-radius: 50%;
			height: rem(50px);
			margin-bottom: rem(30px);
			width: rem(50px);

			svg {
				display: block;
				fill: $purple-heart;
				height: rem(31px);
				width: rem(31px);
			}
		}
	}
}