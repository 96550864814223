.l-footer {
	position: relative;

	> .container {
		padding-bottom: 85px;
		padding-top: 15px;
		position: relative;

		@include media-breakpoint-only(xs) {
			padding-left: 57px;
			padding-right: 57px;
		}

		@include media-breakpoint-only(sm) {
			padding-left: 39px;
			padding-right: 39px;
		}

		@include media-breakpoint-up(md) {
			min-height: 480px;
			padding-bottom: 135px;
			padding-top: 135px;
		}

		@include media-breakpoint-up(xl) {
			min-height: 510px;
			padding-bottom: 120px;
			padding-top: 185px;
		}

		> .row {
			position: relative;
			z-index: 3;
		}
	}

	&-brand {
		margin-bottom: rem(34px);

		@include media-breakpoint-up(md) {
			flex: 0 0 196px;
			margin-bottom: 0;
			max-width: 196px;
			width: 196px;
		}

		@include media-breakpoint-up(xl) {
			flex-basis: 25.909090909090909%; /* 342/1320 */
			max-width: 25.909090909090909%; /* 342/1320 */
			width: 25.909090909090909%; /* 342/1320 */
		}

		svg {
			display: block;
			fill: $white;
			height: rem(32px);
			width: rem(111px);

			@include media-breakpoint-up(md) {
				height: 46px; // Pas en rem, l'espace est trop étroit
				width: 164px; // Pas en rem, l'espace est trop étroit
			}

			@include media-breakpoint-up(xxl) {
				height: rem(46px);
				width: rem(164px);
			}
		}
	}

	&-content {
		color: $white;
		font-size: rem(14px);
		font-weight: 600;
		line-height: rem(20px);

		a {
			color: $white;
			text-decoration: underline;

			&:hover {
				color: $blue-chalk;
			}
		}

		&-copy:not(:first-child) {
			font-weight: 400;
			margin-top: rem(30px);
			opacity: .65;

			a {
				text-decoration: none;

				&:hover {
					color: $white;
				}
			}
		}
	}

	.btn-round {
		position: absolute;
		right: calc($grid-gutter-width * .5);
		top: 0;
		z-index: 2;

		@include media-breakpoint-up(xl) {
			right: calc($grid-gutter-width * .5 + 8.3333%);
		}
	}

	&-blur-gradient,
	&-circle-lines {
		display: block;
		pointer-events: none;
		position: absolute;
	}

	&-blur-gradient {
		height: 624px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 1008px;
		z-index: 1;

		@include media-breakpoint-up(md) {
			height: 797px;
			width: 1411px;
		}

		@include media-breakpoint-up(xl) {
			height: 778px;
			width: 2443px;
		}
	}

	&-circle-lines {
		height: 224px;
		left: 62.325581395348837vw; /* 268/430 */
		mix-blend-mode: soft-light;
		top: 0;
		width: 224px;
		z-index: 1;

		@include media-breakpoint-up(md) {
			height: 410px;
			left: calc($grid-gutter-width * .5);
			width: 410px;
			z-index: 2;
		}

		@include media-breakpoint-up(xl) {
			left: calc($grid-gutter-width * .5 + 8.3333%);
		}
	}
}