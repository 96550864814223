.how-it-works {

	&-steps {
		position: relative;

		&:not(:last-child) {
			margin-bottom: 40px;

			@include media-breakpoint-up(xxl) {
				margin-bottom: 110px;
			}
		}

		> .row {

			> [class^="col"] {

				&:first-child {

					@include media-breakpoint-up(xl) {
						padding-right: 27px;
					}
				}

				> .editor {
					margin-left: auto;
					margin-right: auto;
					max-width: rem(630px);

					@include media-breakpoint-up(xxl) {
						margin: 0;
					}

					h2,
					.h2 {

						@include media-breakpoint-between(md, xl) {
							font-size: rem(48px);
							line-height: 1;
						}
					}
				}

				&:not(:last-child) {
					margin-bottom: rem(40px);

					@include media-breakpoint-up(xxl) {
						margin-bottom: 0;
					}
				}
			}
		}

		&-bg {
			height: 828px;
			left: 50%;
			pointer-events: none;
			position: absolute;
			top: 50%;
			transform: translate(-52%, -28%);
			width: 789px;

			@include media-breakpoint-up(md) {
				transform: translate(-70%, -25%);
			}

			@include media-breakpoint-up(xxl) {
				height: 827px;
				transform: translate(-70%, -45%);
				width: 1005px;
			}
		}
	}

	&-benefits {
		position: relative;
		z-index: 2;
	}
}