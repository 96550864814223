.l-main {
	padding-bottom: 60px;
	padding-top: 20px;
	position: relative;
	z-index: 2;

	@include media-breakpoint-up(md) {
		padding-bottom: 65px;
		padding-top: 23px;
	}

	@include media-breakpoint-up(xl) {
		padding-bottom: 125px;
		padding-top: 50px;
	}
}