.card {
	backdrop-filter: blur(20px);
	background-color: $zircon-5;
	border: 0;
	border-radius: 20px;

	&-body {
		padding: 40px 60px;

		> svg:first-child {
			display: block;
			fill: $electric-violet;
			height: rem(45px);
			margin-bottom: 20px;
			width: rem(45px);
		}
	}

	&-body-item {
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
		position: relative;

		@media (min-width: 430px) {
			flex-wrap: nowrap;
		}

		&:not(:last-child) {
			margin-bottom: 40px;
			padding-bottom: 41px;
			position: relative;

			&:before,
			&:after {
				background-color: $pickled-bluewood;
				bottom: 0;
				content: '';
				height: 1px;
				position: absolute;
			}

			&:before {
				left: 0;
				margin-right: 34px;
				right: 50%;
			}

			&:after {
				left: 50%;
				margin-left: 34px;
				right: 0;
			}

			.arrow-spiral-down {
				bottom: -19px;
				display: block;
				height: 38px;
				left: 50%;
				margin-left: -19px;
				position: absolute;
				stroke: $slate-gray;
				width: 38px;
			}
		}

		&-icon {
			flex: 0 0 100%;
			margin-bottom: rem(20px);
			max-width: 100%;
			width: 100%;

			@media (min-width: 430px) {
				flex-basis: 50px;
				margin-bottom: 0;
				margin-right: 45px;
				max-width: 50px;
				min-height: 1px;
				width: 50px;
			}

			img,
			svg {
				display: block;
				flex: 0 0 50px;
				width: 50px;
			}

			img {
				height: auto;
			}

			svg {
				fill: $electric-violet;
			}

			.wrench {
				flex-basis: 56px;
				height: 56px;
				stroke: $electric-violet;
				width: 56px;
			}

			.rocket {
				flex-basis: 54px;
				height: 64px;
				width: 54px;
			}
		}

		.editor {

			h3,
			.h3 {
				margin-bottom: rem(10px);
			}

			p,
			.p {
				font-size: rem(18px);
				line-height: rem(25px);
			}
		}
	}
}